import React from 'react';

import Navigation from '../components/Navigation';
import Footer from '../components/Footer';

import logoMonkey from "./../assets/icons/logo_hoch-circle.svg";

class ImpressumPage extends React.Component {
  render() {
    return (
      <div>
        <Navigation props={this.props} />
        <div className="uk-section uk-section-default uk-section-large">
          <div className="uk-container uk-container-large">
            <div className="uk-flex uk-flex-center">
              <img src={logoMonkey} height="200" width="200" alt="blindmonkey"/>
            </div>
            <h1 className="uk-text-center">Impressum</h1>

            <h3>Gesetzliche Anbieterkennung:</h3>
            <p>Caarl GmbH <br />
              diese vertreten durch die Geschäftsführer Carsten Aul und Christoph Aul
            </p>
            <p>
              Caarl GmbH<br />
              Geleitstrasse 66<br />
              63456 Hanau<br />
              Deutschland<br />
              Telefon: +49 6181 9068918<br />
              E-Mail: c.aul@caarl.de
            </p>
            <p>
              USt-IdNr.: DE815588810<br />
              eingetragen im Handelsregister des Amtsgerichtes Hanau<br />
              Handelsregisternummer HRB 95363
            </p>

            <h3>Alternative Streitbeilegung:</h3>
            <p>
              Die Europäische Kommission stellt eine Plattform für die außergerichtliche Online-Streitbeilegung (OS-Plattform) bereit,<br />aufrufbar unter <a href="https://ec.europa.eu/odr" target="_blank">https://ec.europa.eu/odr</a>.
            </p>

            <h3>Wir sind seit 12.04.2018 Mitglied der Initiative "FairCommerce".</h3>
            <p>Nähere Informationen hierzu finden Sie unter<a href=">www.fair-commerce.de" target="_blank">www.fair-commerce.de.</a></p>

            <h3>Social Media</h3>
            <p>Dieses Impressum gilt auch für folgende Social Media Profile:</p>
            <ul>
                <li>
                    <span>Facebook: </span>
                    <a href="https://www.facebook.com/blindmonkeygermany/" target="_blank">https://www.facebook.com/blindmonkeygermany/</a>
                </li>
                <li>
                    <span>Instagram: </span>
                    <a href="https://www.instagram.com/blindmonkeygermany/" target="_blank">https://www.instagram.com/blindmonkeygermany/</a>
                </li>
            </ul>
          </div>
        </div>
        <Footer />
      </div>
    )
  }
};

export default ImpressumPage
